

.videosidenav-title {
    display:flex;
    justify-content:space-between;


    width:100%;
    height:80px;
    padding: 5px 10px 0;
    align-self:stretch;
    background-color:rgb(255, 255, 255);
    
}
.videosidenav-lecturevideos {
    color:rgb(0, 0, 0);
    font-size:32px;
    font-weight:bold;
    align-self:center;
}
.videosidenav-expandbtn {
    background-color:rgb(25, 0, 252);
    color:white;
    border-radius:8px;
    border: none;
    align-self:center;
    height:30px;
    margin-bottom:10px;
}
.videosidenav-expandbtn:hover {
    opacity:0.8;
}
.videosidenav-expandbtn:active {
    opacity:1.0;
}
.videosidenav-container-contracted {
    display:flex;
    width: 350px;
}

.videosidenav-container-expanded{
    display:flex;
    width: 600px;
}



.videosidenav {
    display:flex;
    flex-grow:1;
    gap: 2px;
    background-color: rgb(116, 116, 116);
    flex-direction:column;
    overflow-y:scroll;
}

.videosidenav-cardthumb {
    border-radius:5px;
    width:180px;
}








/*Card Styling */
.videosidenav-card-contracted {
    display:flex;
    flex: 50px 1fr;
    justify-content:space-around;
    background-color:rgb(255, 255, 255);
    width: 100%;
    height:130px;
    padding: 10px;
}
.videosidenav-card-expanded {
    display:flex;
    flex: 50px 1fr 2fr;
    justify-content:flex-start;
    background-color:rgb(255, 255, 255);
    width: 100%;
    height:130px;
    padding:10px;
}

.videosidenav-card-contracted:hover,
.videosidenav-card-expanded:hover {
    background-color:rgb(221, 233, 255);
    transition-duration:0.1s;
}

.videosidenav-card-contracted:active,
.videosidenav-card-expanded:active {
    background-color:#a0bffd;
    transition-duration:0.1s;
}

.videosidenav-cardnum {
    align-self:center;
    text-align:center;
    font-size:16px;
    font-weight:bold;
    margin-right:10px;
    color:rgb(61, 61, 61);
}

.videosidenav-card-titlecreator {
    padding: 15px;
}

.videosidenav-card-title {
    color:rgb(0, 0, 0);
    font-weight:bold;
    font-size:16px;
}
.videosidenav-card-creator {
    color:rgb(49, 49, 49);
    font-size:13px;
}

