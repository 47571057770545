.video-grid {
    display:grid;
    width: 91%;
    row-gap: 30px;
    column-gap:40px;
    overflow:hidden;

    margin-left:auto;
    margin-right:auto;
}

@media (max-width:1400px) {
    .video-grid {
        grid-template-columns:repeat(auto-fill, minmax(250px, 1fr));
    }
}
@media (min-width:1400px) {
    .video-grid {
        grid-template-columns:23% 23% 23% 23%;
    }
}