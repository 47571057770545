
/*CARD */
.course-link {
    text-decoration:none;
}
.card {

    padding-bottom:0px;
    padding-top:0px;
    border:none;
    background-color:var(--body-background-color);

    background-color:'#F8E9CF';
    display: flex;
    flex-direction:column;
    font-size:12px;
    flex-grow:1;
}

/*first row img */
.card-thumbnail-container {
    position:relative;
    padding-bottom:56.25%;
    height:0;
}
.card-thumbnail {
    border-radius:15px;
    position:absolute;
    top:0;
    width:100%;
    height:100%;
}
.card-thumbnail:hover {
    opacity:0.7;
    transition-duration:0.1s;
}

/*second row course name*/


/*Second row rating stats */
.card-row {
    display:flex;
    align-items:center;
    margin-bottom:4px;
    margin-left:5px;
}
.star-img {
    height:17px;
}
.card-rating, .card-rating-numreviews, .course-site {
    margin-left:3px;
}
.card-rating-numreviews {
    color:var(--primary-color);
}
.card-rating {
    color:var(--primary-color);
}

/*Final three rows of information*/
.course-cost {
    margin-bottom:2px;
    margin-left:7px;
    margin-top:0px;
    font-weight:bold;
    color:rgb(0, 207, 0);
}
.course-name {
    color:var(--primary-color);
    font-weight:bold;
    font-size:15px;
    margin-left:7px;
    margin-bottom:0px;
}
.course-name:hover{
    text-decoration:underline;
}

.course-creator {
    color:var(--primary-color);
    font-style:italic;
    font-weight:bold;
    font-size:11px;
    margin-bottom:2px;
    margin-left:10px;
}

.card-dot {
    font-size:14px;
    font-weight:bold;
    margin-left:5px;
    color:var(--primary-color);
}