/* Home Ad */

.homead1 {
    display: flex;
    justify-content: center;
  }

@media (max-width:521px) {
    .homead1 {
        display: none
    }
}

.homead2 {
    display: flex;
    width: 90%;
    margin-top:30px;
    max-width: 1000px;
}

.homead3 {
    width: 100%;
    border-radius: 15px;
    border: 1px solid black;
}

/*
.ad-img-text-container {
    display: flex;
    width: 270px;
    height: 200px;
    position: absolute;

    top: 55%;
    left: 22.5%;
    transform: translate(-50%, -50%);

    font-size: 2em;
    text-align:left;
}
.ad-img-text {
    font-size: 37px;
    font-weight:bold;
    color: rgb(54, 86, 173);
}
*/














/*
.video-grid {
    display:grid;
    width: 91%;
    gap: 20px;
    overflow:hidden;

    margin-top:50px;
    margin-left:auto;
    margin-right:auto;
}

@media (max-width:1000px) {
    .video-grid {
        grid-template-columns:repeat(auto-fill, minmax(230px, 1fr));
    }
}
@media (min-width:1000px) {
    .video-grid {
        grid-template-columns:23% 23% 23% 23%;
    }
}

.recommended {
    font-weight:bold;
    margin-top:20px;
    margin-bottom:-20px;
    margin-left:20px;
    color:rgb(197, 185, 19);
    text-shadow: 1px 1px #455705;
    font-size:40px;
}*/


