:root {
    --primary-color:rgb(255, 255, 255);
    --secondary-color:rgb(19, 19, 75);
    --focus-color:rgb(45, 45, 233);
    --focuslight-color:rgb(0, 183, 255);
    --accent-color:rgba(0, 189, 0, 0.836);

}

body{
    font-family:'Lato', sans-serif;
    background-color:rgb(235, 235, 235);
    color:var(--primary-color);
    min-height: 100vh;
}