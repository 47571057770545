/*wrapper of all video player content */
.vp-wrapper {
    height: fit-content !important;
    background: none;

    margin-left:auto;
    margin-right:auto;
    padding: 1rem;
    
    display: flex;
    flex-direction:column;
}

/*wrapper of video*/
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    margin-bottom: 8px;
  }

.videoWrapper iframe {
position: absolute;
top: 0;
width: 100%;
height: 100%;
border-radius: 20px;
}


/*video title, creator*/
.video-title-cont {
    color:var(--secondary-color);
}

.video-title {
    font-size: 30px;
    font-weight: bold;

    margin-bottom: 3px;
}

.video-creator {
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    
    margin-top: 0px;
    margin-bottom: 5px;
    padding-bottom: 0px;
}


