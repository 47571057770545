
.chatbox {
    background-image:linear-gradient(white, rgb(172, 172, 211));
    display: flex;
    flex-direction: column;

    height: 100%;
    width: 100%;

    border: 1px solid black;
    border-radius: 20px;
}

/*Chatbox Header*/
.chat-header {
    display:flex;
    height: 100px !important;
    margin-top: -1px;
    padding: 17px 20px 10px;
    background-color: rgb(53, 18, 250);
    border-top-left-radius:20px;
    border-top-right-radius:20px;

    font-size: 30px;

}

.chatheader-text {
    color:white;
    margin-right: 15px;
    font-size:32px;
}
.chat-bot-headerimg {
    height:100%;
    margin-right:15px;
    margin-top:-5px;
}



/*Chat Previous Messages */
.chat-messages-container {
    display: flex;
    flex-grow:1;
    flex-direction: column;

    overflow:scroll !important;
    width: 100%;
    margin: 0;
    padding-bottom: 20px;
    padding-top:10px;
}
.chat-ai-msg, .chat-user-msg {
    max-width: 75%;
    margin-top: 24px;
    color: black;
    
    border-radius: 15px;
}

.chat-user-msg {
    padding: 5px 10px 5px 10px;
    margin-right: 10px;
    background-color: rgb(38, 153, 27);
    color:rgb(255, 255, 255);
    align-self: flex-end;
}

.chat-ai-msg {
    display:flex;
    padding: 5px 10px 5px 10px;
    margin-left: 10px;
    text-align: left;
    background-color: rgb(53, 83, 253);
    color:white;
    align-self: flex-start;
}

.chat-botimg {
    height:40px;
    margin-right:15px;
}



/*Submit Area*/
.chat-input-cont {
    display:flex;
    flex-grow:0;
    justify-content:space-between;
    height:100px;
    width:100%;
    padding: 15px 15px 15px;
}

.chat-input {
    resize:none;
    flex-grow:0.95;

    min-height:35px;
    margin-right: 10px;
    margin-top: 3px;

    padding-left: 10px;
    padding-right: 10px;
    padding-top: 3px;

    border: 1px solid black;
    border-radius: 15px;

    font-size: 17px;
}
.chat-input:focus{
    border:1px solid blue;
}

.chat-submit-btn {
    background-color: rgb(17, 17, 250);
    color:white;
    font-weight:bold;
    height: 40px;
    width: 100px;
    border: none;
    border-radius: 20px;
}
.chat-submit-btn:hover {
    background-color:white;
    color:rgb(17, 17, 250);
    border:1px solid rgb(17, 17, 250);
    transition-duration:0.3s;
    font-weight:bold;
}
.chat-submit-btn:active {
    background-color: rgb(17, 17, 250);
    color:white;
    border:none;
    opacity:0.8;
}
