/*Favorite Courses*/
.favcourses {
    margin-top:40px;
}

.favcourses-title {
    font-size: 35px;
    font-weight:bold;
    margin-left: 4.5%;
    margin-bottom:20px;
}


/*Recommended Courses */
.rec-courses {
    margin-top:40px;
}
.rec-courses-title {
    font-size: 35px;
    font-weight:bold;
    margin-left: 4.5%;
    margin-bottom:20px;
}