.vp-page {
    display:flex;
}



/*Vp sidebar below */
.vp-sidebar {
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    
    margin-top: 50px;
    padding: 10px 0 10px;

    height: 300px;

    background-color:var(--primary-color);
    border-top-right-radius:8px;
    border-bottom-right-radius:8px;
    color:rgb(17, 0, 77);
}

.vp-sidebar-direct {
    display: flex;
    flex-direction:row;

    padding-top: 5px;
    width: 100%;
}

.vp-sidebar-direct:hover {
    background-color: rgb(192, 236, 248);
}
.vp-sidebar-direct:hover {
    cursor:pointer;
}

.vp-sidebar-direct-img {
    height: 65px;

    padding: 5px 5px 5px;

    align-self: center;
}





.Resizer {
    background-image: linear-gradient(90deg, white, grey);
    opacity: 0.7;
    width: 50px;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
  }
   
  .Resizer:hover {
    background-image: linear-gradient(90deg, white, rgb(142, 153, 255));
    cursor:col-resize;
    -webkit-transition: all 2s ease;
    transition: all 1s ease;
  }
   
  .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
  }
   
  .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
  }
   
  .Resizer.vertical {
    width: 6px;
    color:white;
    margin: 0 -3px;
    border-left: 3px solid var(--primary-color);
    border-right: 3px solid var(--primary-color);
    cursor: col-resize !important;
  }
   
  .Resizer.vertical:hover {
    width:10px;
    color:white;
    border-left: 2px solid rgb(95, 123, 250);
    border-right: 2px solid rgb(95, 123, 250);
    cursor:col-resize;
  }
  .Resizer.disabled {
    cursor: not-allowed;
  }
  .Resizer.disabled:hover {
    border-color: transparent;
  }



/*SplitPane*/
.vp-sidebar-left, .vp-sidebar-right {
  background-color:var(--secondary-color);
  display:flex;
  flex-direction:column;
  min-height:1000px;
}




/*DND WIDGET */
.dnd-widget {
  display: flex;
  flex-direction:column;

  resize:vertical;
  overflow:auto;

  align-self:center;
  width:90%;
  min-width:None;
  background-color:var(--primary-color);

  -moz-box-shadow: 0 0 25px rgb(39, 39, 39);
  -webkit-box-shadow: 0 0 25px rgb(39, 39, 39);
  box-shadow: 0 0 25px rgb(39, 39, 39);
  color:black;

  margin:20px 0 20px;
  padding:10px;
  border-radius:8px;
}
.dnd-widget-topbar {
  height:40px;
  margin:none;
  display:flex;
  justify-content:space-between;
}

.dnd-widget-title {
  font-size:30px;
  padding:10px 0 5px;
  align-self:center;
  font-weight:bold;
}
.dnd-widget-moveicon {
  width:30px;
  height:30px;
  align-self:center;
}

.dnd-widget-elementwrapper {
  border:none;
  height:100%;
}



/*noteswrapper */
.noteswrapper {
  min-height:300px;
}


.notes-selector {
  display:flex;
  flex-direction:column;
  border: 1px solid black;
  height:500px;
  overflow:scroll;
}
.notes-selector-headers {
  display:flex;
  justify-content:space-between;
  align-items:center;
  padding: 10px 10px 10px 5px;
}
.notes-selector-headers > p {
  margin:0;
  font-weight:bold;
  font-size:18px;
}
.notes-selector-row {
  display:flex;
  align-items:center;
  height:50px;

  margin:0;
  padding:0 10px 0 0;
}
.notes-selector-row:hover {
  background-color:rgb(199, 199, 199);
  cursor:pointer;
}
.notes-selector-name {
  justify-self: flex-start;
  margin: 0 0 0 20px;
  padding:0;
}
.notes-selector-date {
  margin-left:auto;
}
.notes-docicon {
  height:65%;
  justify-self: flex-start;
}

.notes-selector-divider {
  margin:0;
  padding:0;
}