.navbar {
    display:flex;
    padding:13px 15px 13px 15px;
    align-items:center;
    justify-content:space-between;

    /*background:linear-gradient(var(--focus-color), var(--secondary-color));*/
    background-color:var(--secondary-color);
    box-shadow: -2px 4px 4px -2px var(--secondary-color);
}

.navbar-sitename {
    color:var(--primary-color);
    font-size:25px;
    font-weight:bold;
    margin:0;
    text-decoration:none;
}
 

.navbar-directs {
    display:flex;
    align-items:center;
    gap:25px;

    margin:0;
    
}
.navbar-categories, .navbar-login {
    margin:0;
    font-size:18px;
    color:white;
    text-decoration:none;
}
.navbar-categories:hover, .navbar-login:hover {
    text-decoration:underline;
}
.navbar-signup {
    margin:0;
    display:flex;
    align-items:center;
    justify-content:center;

    width:120px;
    height:40px;
    font-size:18px;
    font-weight:bold;
    text-decoration:none;
    
    background:linear-gradient(rgb(0, 212, 0), green);
    border:none;
    border-radius:8px;
    color:var(--primary-color);
}
.navbar-signup:hover {
    background:var(--primary-color);
    color:rgb(0, 212, 0);

    -moz-box-shadow: 0 0 8px rgb(0, 212, 0);
    -webkit-box-shadow: 0 0 8px rgb(0, 212, 0);
    box-shadow: 0 0 8px rgb(0, 212, 0);


}



/*OLD BELOW*/
/*NAVBAR (With 3 items)*/

#myNav {
    padding-left:15px;
    background:linear-gradient(var(--focus-color), var(--secondary-color));
    color:var(--primary-color);
    width: 100%;
    padding-bottom:10px;
    padding-top:10px;

    /*add shadow*/
    -webkit-box-shadow: 0 8px 6px -6px var(--focus-color);
    -moz-box-shadow: 0 8px 6px -6px var(--focus-color);
    box-shadow: 0 5px 6px -4px var(--focus-color);
}


/*Nav item 1: Logo and Name*/
#nav-brand {
    color:var(--primary-color);
    display:flex;
    flex-grow:1;
}
#nav-logo {
    margin-right:10px;
    align-self:center;
}
.nav-sitename {
    align-self:center;
}


/*Nav item 2: Searh Bar*/
#nav-search-form {
    padding-right:10px;
    flex-grow:1;
    max-width:500px;
    align-self:center;
}
#search-input {
    min-width:100px;
    max-width:500px;
    flex-grow:1;
}
#search-button {
    color:rgb(255, 255, 255);
    border:none;
    background-color:rgb(67, 184, 0);
    border-radius:10px;
    font-weight:bold;
    font-size:16px;
}
#search-button:hover{
    background-color: white;
    color:rgb(67, 184, 0);
    border:none;
}
#search-button:active {
    background-color: rgb(67, 184, 0);
    color: white;
}



/*Nav item 3: Links*/
.nav-link, #nav-cat-drop, #nav-login{
    color:var(--primary-color);
    font-weight:bold;
}
.nav-link:hover, #nav-cat-drop:hover, #nav-login:hover {
    color: rgb(182, 180, 180);
}

#nav-signup {
    display:flex;
    align-content:center;
    justify-content:center;

    width:110px;
    font-size:18px;
    height: 40px;
    border-radius:8px;
    background-color:var(--focuslight-color);
}
#nav-signup >p{
    margin-top:-2px;
}
#nav-signup:hover {
    background-color:var(--primary-color) !important;
    color:var(--focuslight-color) !important;
}

#navbarNav {
    margin-right:5px;
}
#nav-hamburger {
    margin-right:10px;
    color:var(--primary-color) !important;
    background-color:var(--primary-color);
}
.navbar-toggler-icon {
    color:var(--primary-color) !important;
}
.dropdown-item {
    cursor:pointer;
}


.navbar-profilepic {
    height:50px;
}