.courseinfo-page {
    display: flex;
    flex-direction:column;
    background-color:rgb(233, 233, 233)
}




/*Basic Info and Thumbnail */
.courseinfo-top-container {
    width:100%;
    padding: 50px 50px 50px 50px;
    background-color:rgb(245, 245, 245);
    flex-grow:1;
    justify-content:space-around;
    display:flex;
    flex-direction:row;
}

@media (max-width:1050px) {
    .courseinfo-top-container {
        display:flex;
        flex-direction:column;
        margin: 0 auto 0;
    }
    .courseinfo-topleft {
        margin-bottom:40px;
    }
    .courseinfo-topright {
        align-self:center;
        margin-left:-40px;
    }

    .courseinfo-middlecontainer {
        max-width:100%;
        display:flex;
        flex-direction:column;
    }
    .courseinfo-detailedinfo {
        max-width:100%;
    }
    .courseinfo-videos-container {
        flex-grow:1 0 0;
        max-width:100% !important;
    }
}

.courseinfo-topleft {
    background-color:var(--primary-color);
    padding:20px;
    border:1px solid rgb(158, 158, 158);
    border-radius:15px;
    flex: 1;

    -moz-box-shadow: 0 0 40px rgb(0, 0, 0);
    -webkit-box-shadow: 0 0 40px rgb(0, 0, 0);
    box-shadow: 0 40px rgb(117, 117, 117);
}

.courseinfo-thumb {
    width:100%;
    min-width:none;

    border-radius: 15px;
}
.courseinfo-namecreator-cont {
    margin-top:8px;
}

.courseinfo-name {
    color:var(--secondary-color);
    font-size:40px;
    font-weight:bold;
    margin:0;
}

.courseinfo-creator{
    color:var(--secondary-color);
    font-size:20px;
}

.courseinfo-startcoursebtn{
    height:50px;
    width:250px;
    margin-top:20px;

    color:var(--primary-color);
    background-color:var(--focus-color);

    border:none;
    border-radius:20px;
    font-weight:bold;
    font-size:20px;
}
.courseinfo-startcoursebtn:hover{
    background-color:var(--primary-color);
    color:var(--focus-color);
    transition-duration:0.2s;

    -moz-box-shadow: 0 0 8px var(--focus-color);
    -webkit-box-shadow: 0 0 8px var(--focus-color);
    box-shadow: 0 0 8px var(--focus-color);
}



.courseinfo-topright {
    display:flex;
    flex-direction:column;
    align-self:stretch;
}

.courseinfo-basicinfo-cont {
    width:80%;
    padding: 10px 20px 10px;
    margin-left:40px;
    margin-top:10px;
    margin-bottom:auto;
    background-color:var(--primary-color);
    border: 1px solid rgb(209, 209, 209);
    width:1fr;
    border-radius:15px;
}
.courseinfo-reviews-row {
    display:flex;
    flex-direction:row;
}
.courseinfo-numreviews {
    align-self:flex-end;
    font-size:22px;
    margin-top:12px;
    margin-left:10px;
    color:rgb(5, 5, 184);
}
.courseinfo-numreviews:hover {
    color:rgb(62, 91, 255);
    cursor:pointer;
}

.courseinfo-basicinfo-row {
    margin-top:12px;
    width:100%;
    display:flex;
    justify-content:space-between;
}
.courseinfo-basicinfo-left {
    color:var(--secondary-color);
    margin-right:10px;
    font-size:22px;
}

.courseinfo-basicinfo-right {
    color:var(--secondary-color);
    font-weight:bold;
    font-size:22px;
}




/*Course overview*/
.courseinfo-middlecontainer {
    display:flex;
    flex-grow:1;
    flex-shrink:1;
    background-color:var(--secondary-color);
    padding: 20px 30px 50px;
    gap:20px;
}



.courseinfo-detailedinfo {
    flex-grow: 1;
    flex-shrink:1;
    min-width:0;
    align-self:flex-start;
    background-color:var(--primary-color);
    color:var(--secondary-color);
    border: 1px solid rgb(209, 209, 209);
    padding: 30px;
    border-radius:8px;
}

.course-overview-title, .course-overview-prereqtitle {
    font-size:32px;
    font-weight:bold;
    margin-bottom:0px;
    margin-top:5px;
}

.course-overview-descr {
    min-width:0;
    font-size:23px;
    padding-left:10px;
    padding-top:5px;
    padding-bottom:30px;
    overflow-x:scroll;
}

.courseinfo-videos-container {
    flex: 0 0 content;
    padding:5px;
    background-color:var(--primary-color);
    border: 1px solid rgb(209, 209, 209);
    border-radius:8px;
    align-self:flex-start;
}

.courseinfo-videosidenav {
    max-height:500px;
    overflow-y:scroll;
}


/*Comments Section */
.courseinfo-comments {
    border: 1px solid black;
    
    margin: 10px 10px 10px;
    height:200px;
}




/* OLD 
.courseinfo-basicinfo  {
    background-color:rgb(25, 0, 255);
    flex-grow:1;



    border-top-right-radius:15px;
    border-bottom-right-radius:15px;
    padding: 10px 10px 10px;

    width: 1fr;
}

.courseinfo-title {
    font-size:20px;
    font-weight:bold;

    margin-bottom:-5px;
}

.courseinfo-basicinfo-flex {
    display: flex;
    flex-direction:row;
    align-items:center;
    justify-content:space-between;
}



*/
