
.code-editor {
    flex-grow: 1;


    padding-top: 10px;
    margin: 0 10px 10px;
}

.ce-title-container {
    justify-content:space-between;
    display: flex;

    border-top-left-radius: 15px;
    border-top-right-radius:15px;
    padding: 10px 4px 0px;
    background-color:rgb(41, 41, 41);
    color:white;
}
.ce-tabs-container {
    display:flex;
}
.ce-title-tab {
    border: 1px solid black;
    background-color:rgb(58, 58, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 60px;
    margin:0 2px 0;
    padding: 5px 5px 5px;

    text-align:center;
    opacity:0.5;
}
.ce-title-tab-clicked {
    border: 1px solid black;
    background-color:rgb(58, 58, 255);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 60px;
    margin:0 2px 0px;
    padding: 5px 5px 5px;

    text-align:center;
    opacity:1;
}
.ce-runcodebtn {
    border:none;
    background-color:rgb(58, 58, 255);
    border-radius:8px;
    color:white;
    align-self:center;
    height:38px;
    margin-bottom:5px;
    margin-right:15px;
    font-size:18px;
    font-weight:bold;
}
.ce-runcodebtn:hover {
    background-color:rgb(39, 96, 253);
    transition-duration:0.15s;
}
.ce-runcodebtn:active {
    background-color:rgb(58, 58, 255);
    transition-duration:0.15s;
}

.ce-title-tab:hover {
    opacity:0.85;
    cursor:pointer;
}
.ce-title-tab-clicked:hover {
    cursor:pointer;
}

.editor-container {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
}


.top-pane {
    background-color:rgb(233, 233, 233);

    border-top: 1px solid black;

    display:flex;
    flex: 1 1 1;
    gap: 20px;
    padding: 0;
}

.pane {
    height: 50vh;
    display: flex;
    flex-direction:row;
    background-color:white;
}

.editor-container {
    flex-grow:1;
    flex-basis:0;
    display: flex;
    flex-direction:column;
    
    margin: 0;
    height: 100%;
    width: 100%;
}

.editor-title-container {
    display:flex;
    justify-content:space-between;

    padding: 0 10px 0;

    background-color:rgb(27, 128, 211);
}

.editor-title {
    padding-top: 10px;
    color:white;

    font-size: 18px;
    font-weight: bold;
}

.code-mirror-wrapper {
    flex-grow: 1;
    overflow:hidden;
}

.CodeMirror {
    height: 100% !important;
    width: 100% !important;
}

.bottom-pane {
    border: 2px solid black;
    margin: 10px 0px 10px;
}