.lp-page {
    overflow:hidden;
}

/*lp-intro*/
.lp-intro {
    display: flex;
    align-items:center;
    justify-content:center;
    padding:120px 50px 100px;
    gap:5%;
    background-color:var(--primary-color);
    color:var(--secondary-color);
}
.lp-intro-img {
    width:500px;
}
.lp-intro-right {
    flex-grow:1;
    flex-shrink:1;
    max-width:800px;
    display:flex;
    gap:10px;
    flex-direction:column;
}

.lp-intro-btn {
    opacity:1.0;
    margin-top:50px;
    background-color:blue;
    color:white;
    font-size:25px;
    font-weight:bold;
    width:250px;
    height:50px;
    border: none;
    border-radius:20px;
}
.lp-intro-btn:hover {
    transition: 0.12s linear;
    background-color:white;
    color: blue;

    -moz-box-shadow: 0 0 8px rgb(0, 0, 182);
    -webkit-box-shadow: 0 0 8px rgb(0, 0, 182);
    box-shadow: 0 0 8px rgb(0, 0, 182);
}
.lp-intro-btn:active {
    transition-duration: 0.12s;
    color:white;
    background-color:blue;
}

.lp-intro-text {
    font-size:50px;
    font-weight:bold;
}
.lp-intro-text-small {
    font-size:20px;
}


/*lp-groups*/
.lp-groups {
    background-color:var(--primary-color);
    color:var(--secondary-color);
    padding: 60px 0 60px;

    display:flex;
    flex-direction: column;
    gap: 80px;

    align-items:center;
    justify-content:center;
}
.lp-groups-text {
    text-align:center;
    color:var(--secondary-color);
    font-size:50px;
    font-weight:bold;
}
.lp-groups-img-cont {
    margin:0;
    padding:20px 40px 20px;
    width:100%;

    display:flex;
    align-items:center;
    justify-content:center;
    gap:10%;
}

.lp-groups-img {
    width:450px;
}

.lp-groups-right {
    min-width:300px;
    max-width:600px;
    border-radius:25px;
    padding:10px 20px 40px;
    display:flex;
    flex-direction:column;
    gap:40px;

    /*
    -moz-box-shadow: 0 0 3px rgb(58, 58, 58);
    -webkit-box-shadow: 0 0 3px rgb(58, 58, 58);
    box-shadow: 0 0 3px rgb(58, 58, 58);
    */
}

.lp-groups-text-small {
    margin-top:20px;
    color:var(--secondary-color);
    font-size:28px;
    font-weight:bold;
    width:100%;
}

.lp-groups-btn {
    background-color:var(--focuslight-color);
    color:var(--primary-color);
    flex-shrink:0;
    font-size:25px;
    font-weight:bold;
    padding: 5px 15px 5px;
    border:none;
    border-radius:25px;
    height: 50px;
    align-self:flex-start;


}

.lp-groups-btn:hover {
    transition: 0.12s linear;
    background-color:var(--primary-color);
    color: var(--focuslight-color);


    -moz-box-shadow: 0 0 8px var(--focuslight-color);
    -webkit-box-shadow: 0 0 8px var(--focuslight-color);
    box-shadow: 0 0 8px var(--focuslight-color);
}
.lp-groups-btn:active {
    transition: 0.12s linear;
    background-color:blue;
    color: white;
}


/*lp-features*/
.lp-features {
    display:flex;
    align-items:center;
    justify-content:space-between;
    min-height:0;
    padding:60px 150px 60px 3%;
    background-color:var(--secondary-color);
    color:var(--primary-color);
}
.lp-features-text {
    text-align:center;
    width:250px;
    font-size:50px;
    font-weight:bold;
}


.lp-features-img-container {
    position:relative;
    width:50%;
    padding-bottom:50%;
    margin:50px;
}
.lp-features-img{
    position:absolute;
    width:100%;
    height:100%;
    object-fit:cover;

}
.lp-features-innerimg1-cont, .lp-features-innerimg2-cont,
.lp-features-innerimg3-cont, .lp-features-innerimg4-cont,
.lp-features-innerimg5-cont {
    position:absolute;
    height:20%;
    width:20%;

    display:flex;
    align-items:center;
    justify-content:center;

    border-radius:50%;
    overflow:hidden;
}

.lp-features-innerimg1-cont{
    top:32.18%;
    left:2.45%;
    height:19.5%;
    width:19.5%
}
.lp-features-innerimg1 {
    height: 120%;
}

.lp-features-innerimg2-cont{
    top:5.6%;
    left:18.6%;
    height:21%;
    width:21%;
}.lp-features-innerimg2 {
    height:120%;
}

.lp-features-innerimg3-cont {
    top:5.7%;
    left:52%;

    height:19%;
    width:19%;
}
.lp-features-innerimg3{
    height:115%;
}

.lp-features-innerimg4-cont {
    top:20.6%;
    left:74.7%;
    height:19%;
    width:19%;
}
.lp-features-innerimg4 {
    height:110%;
}

.lp-features-innerimg5-cont {
    top:48%;
    left:78.8%;
    height:18.5%;
    width:18.5%;
}


.lp-features-innerimg5 {
    height:100%;
}


.lp-features-focusinfo1-cont, .lp-features-focusinfo2-cont, 
.lp-features-focusinfo3-cont, .lp-features-focusinfo4-cont,
.lp-features-focusinfo5-cont {
    position:absolute;

    background:linear-gradient(135deg, var(--focuslight-color), var(--focus-color));
    color: var(--primary-color);
    

    border: 2px solid rgb(26, 26, 26);
    border-radius: 30px;
    padding:0;
    width:40%;
}
.lp-features-focusinfo-text {
    font-weight:bold;
    font-size:15px;
    margin: 5% 2% 5% 7%;
}

.lp-features-focusinfo1-cont{
    top: 25%;
    left:-38.5%;
    
}
.lp-features-focusinfo2-cont{
    left:-20%;
    top: -5%;
}
.lp-features-focusinfo3-cont {
    left:70%;
    top:-8%;
}
.lp-features-focusinfo4-cont {
    left:92%;
    top:6%;
}
.lp-features-focusinfo5-cont {
    left:97%;
    top:34%;
}



/*lp-reviews */
.lp-reviews {
    display:flex;
    flex-direction:column;
    align-items:center;
    text-align:center;

    background-color:var(--primary-color);
    color:var(--secondary-color);


    padding: 100px 0  100px;
    
}
.lp-reviews-title {
    font-size:50px;
    margin-bottom:50px;
    font-weight:bold;
}

.lp-reviews-reviewcontainer {
    display:flex;
    width: 70%;
    margin-top:60px;
    flex-direction:column;
    align-items:center;
    text-align:center;
}

.lp-reviews-review {
    font-size:35px;
    font-weight:bold;
}
.lp-reviews-reviewer {
    font-size:20px;
    font-weight:bold;
    margin-top:10px;
}



/*lp-courses */
.lp-courses {
    display:flex;
    flex-direction:column;
    align-content:center;
    align-self:center;
    background-color:var(--secondary-color);
    padding:40px;
}
.lp-courses-text {
    color:var(--primary-color);
    font-size:50px;
    font-weight:bold;
    text-align:center;
}

.lp-coursebutton, .lp-coursebutton-hover {
    background:transparent;
    color:var(--primary-color);
    border: 1px solid white;
    border-radius:25px;
    font-size:18px;
    font-weight:bold;

    padding: 5px 10px 5px;
    margin-right:10px;
    margin-top:10px;
}
.lp-coursebutton-hover {
    background-color:var(--focuslight-color);
    border:none;
    transition: 0.1s linear;
}
.lp-coursebuttons-container {
    text-align:center;
    width:80%;
    align-self:center;
    margin-top:60px;
    margin-bottom:50px;
}




@media (max-width:850px) {

    /*lp-intro*/
    .lp-intro {
        display:flex;
        flex-direction:column;
    }
    .lp-intro-img {
        width:90%;
    }
    .lp-intro-right {
        width:90%;
    }


    /*lp-groups*/
    .lp-groups {
        gap:20px;
    }
    .lp-groups-img-cont {
        display:flex;
        flex-direction:column;
        gap:40px;
    }
    .lp-groups-img {
        width:90%;
    }
    .lp-groups-right {
        min-width:none;
    }
    .lp-groups-btn {
        max-width:90%;
    }

    /*lp-features */
    .lp-features-focusinfo-text {
        font-size:11px !important;
    }
}

@media (max-width:1150px) {
    .lp-features {
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        
        padding:50px 0 50px;
    }
    .lp-features-text {
        width:80%;
        margin-top:50px;
    }
    .lp-features-img-container {
        height:0;
        width:57%;
        padding-bottom:57%;
    }
    .lp-features-focusinfo-text {
        font-size:13px;
    }
}