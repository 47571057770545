
.stars {
    position: relative;
    margin-top: 5px;
}

.empty-stars, .filled-stars {
    display:flex;
    flex-direction:row;
}
.empty-star, .filled-star {
    height: 30px;
    width: auto;
}

.filled-stars {
    position:absolute;
    top:0;
    left:0;
    overflow: hidden;
}