
/*FOOTER*/
.footer {
    width:100%;
    /*background: linear-gradient(to bottom, rgb(9, 46, 255) , var(--secondary-color));*/
    background-color:var(--secondary-color);
    padding-left:10px;
    padding-right: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin-top: 60px;
    color: var(--primary-color);
    font-size: 13px;
}

.footer-row {
    width: 85%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

/*column titles*/
.footer-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

/*column styling*/
.footer-about-col {
    flex:1;
    margin-right: 80px;
}

.footer-quicklinks {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-quicklinks > li {
    padding: 0;
    margin:0;
}
.footer-quicklinks > li:hover {
    text-decoration:underline;
    cursor:pointer;
}



/*horizontal line style */
.footer-break {
    border: 1px solid var(--primary-color);
    opacity: 0.6;
    width: 95%;
    margin-right: auto;
    margin-left: auto;
}

/*copy right portion*/
.footer-cont {
    text-align: center;
    margin-top:25px;
}

.footer-copyright {
    justify-self: center;
}